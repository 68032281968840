import React, { useState, useEffect }  from 'react'; //ChangeEvent, 
import { Button } from '@mui/material';

import { getSubscriptionTier, getSubscriptionTierAll } from '../util/subscription'
import { post } from './../config/axiosConfig';

import '../style/Contact.css'

const buttStyle = { backgroundColor: '#1b5e20' };
const buttStyleCancel = { backgroundColor: '#903131' };

/**
 * Map subscriptions to a table so each can be cancelled
 * created, renews
 * make cancellation a pop up confirmation modal
 */

// &#8203; is a 'soft break', a break point when screen is too narrow, otherwise ignored
const Account = () => {
  const [subscriptionTier, setSubscriptionTier] = useState({
    id: null,
    name: 'No Subscription Found',
    user_limit: 0,
    promo_credit: 0,
    is_renewing: false,
    renewal_date: null
  });

  const [subscriptionTiers, setSubscriptionTiers] = useState();

  useEffect(() => { // initial token wallet load on login
    (async() => {
      const subTierCurrent = await getSubscriptionTier();
      if(subTierCurrent){
        setSubscriptionTier(subTierCurrent);
      }
    })();

    (async() => {
      const subs = await getSubscriptionTierAll();
      console.log(subs)
      if(subs){
        setSubscriptionTiers(subs);
      }
    })();
  }, [])

  async function resetPassword() {
    try {
      // let response = await post(`reset-password`);
      alert(`Password reset is not available yet. Tell me you need this and I'll make it happen`);
    } catch (e){
      console.log(e)
    }
  }

  async function cancelSubscription(subscription_id) {
    try {
      let response = await post(`cancel-subscription/${subscription_id}`);
      console.log(response);
      alert(response.data.message);
    } catch (e){
      console.log(e)
    }
  }

    return (
        <div className = 'contact'>
          <div className='contact-align'>{subscriptionTier.id &&
            <>
              <p>You are currently subscribed to the {subscriptionTier.name} tier</p>
              <p> You can find digital leeches {subscriptionTier.user_limit > 100000 ? 'with no follower limit.' : `on accounts with up to ${subscriptionTier.user_limit} followers`}</p>
          </>
          }

          {!subscriptionTier?.id && <>
            <p>You are not subscribed to any subscription tier</p>
          </>}

          <p> </p>
          <Button
              variant="contained"
              onClick={(e) => {resetPassword(e)}}
              style={buttStyle}
            >Reset Password</Button>
          <p> </p>

          <div className='subs-tier'>
            { subscriptionTiers?.map(sub => {
              return <div className='' title={sub.id}>
                Subscription: {sub.name}<br />
                Account User Limit: {sub.user_limit}<br />

                {sub.is_renewing ?
                  <>
                  <Button
                    variant="contained"
                    onClick={(e) => {cancelSubscription(sub.id)}}
                    style={buttStyleCancel}
                  >Cancel Subscription</Button><br /><br />
                  </>
                  :
                  <>
                    Subscription expires on {new Date(sub.renewal_date).toLocaleString()}
                  </>
                }
                
                </div>
              })
            }
          </div>

          </div>
        </div>
    )
}

 // TODO add hover text and disable upload button when no files are selected
 //      

export default Account;
