import { useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js"; //Elements,

import { STRIPE_API_KEY } from '../config/envVars'

import { ProductContext } from "../state/contexts";

import './../style/Checkout.css'

const stripePromise = loadStripe(STRIPE_API_KEY);

export default function SubscriptionCheckoutWrapper() {
  const { clientSecret } = useContext(ProductContext);
  const options = {clientSecret}

  return (
    <div className="CheckoutWrapper">
      <div className="product-window">
      </div>
      {clientSecret && (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={options}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
      )}
      <p style={{fontSize: '.5em'}}>This purchase will show up as "Nick Comp Services" or "Nick CS" on your reciept and statements</p>
      <br />
    </div>
  );
}


/*
useState, useEffect, 
// import Checkout from './Checkout'
import { get } from '../config/axiosConfig' //, post
  import picker from './../img/leech_picker.png';
  import surgeon from './../img/leech_surgeon.png';
  import slayer from './../img/leech_slayer.png';

    // const prodImgs = [picker, surgeon, slayer];

  const [ userEmail, setUserEmail ] = useState('');

  useEffect(() => {
    const getEmailForStripeAutofill = async () => {
      try {
        console.log('get email');
        let response = await get('email');
        setUserEmail(response?.data?.email.toString() || '');
      } catch (error) {
        console.log(error);
      }
    }

    getEmailForStripeAutofill();
  }, []);

  const appearance = {
    theme: 'night',
  };
  const options2 = {
    clientSecret,
    appearance,
  };
  */