import React from 'react'
import { Dialog, DialogTitle } from '@mui/material';
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from 'recharts';

const LineChartDialog = ({showGraphDialog, setShowGraphDialog, graphData}) => {
    const chartWidth = 800;
    const chartHeight = 250;
    return (
        <Dialog
            open={showGraphDialog}
            onClose={()=>{setShowGraphDialog(false)}}
            fullWidth
            maxWidth="md">
            <DialogTitle>Leeches and Suckers</DialogTitle>
            <LineChart width={chartWidth} height={chartHeight} data={graphData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="createdts" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" name='Number of Leeches' dataKey='numLeeches' stroke="#8884d8" />
                <Line type="monotone" name='Number of Suckers' dataKey='numSuckers' stroke="#82ca9d" />
            </LineChart>
            <DialogTitle>Followers and Following</DialogTitle>
            <LineChart width={chartWidth} height={chartHeight} data={graphData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="createdts" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" name='Number of Followers' dataKey='numFollowers' stroke="#8884d8" />
                <Line type="monotone" name='Number of Following' dataKey='numFollowing' stroke="#82ca9d" />
            </LineChart>
        </Dialog>
    )
}

export default LineChartDialog;
