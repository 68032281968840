import React from 'react'; //ChangeEvent, 
import { useNavigate } from "react-router-dom";
import { Button } from '@mui/material';

import '../style/Contact.css'

const buttStyle = { backgroundColor: '#1b5e20' };


// &#8203; is a 'soft break', a break point when screen is too narrow, otherwise ignored
const Faq = ({loginNav}) => {
  const navigate = useNavigate();

  const handleNavigateToLogin = async e => {
    e.preventDefault();
    navigate("../", { replace: true }); 
  }

    return (
        <div className = 'contact'>
          <div className='contact-align'>
            <h4 className='contact-question'>Contact Information</h4>
            <p>Nick Computational Services, LLC</p>
            <p>#1022, 2430 Butler Street, Easton, PA 18042</p>
            <p>(267) 874-1111</p>
            <p><a href="mailto:support@digitalleechfinder.com">support@&#8203;digitalleechfinder.com</a></p>
          
            { loginNav &&
                <div>
                  <br />
                  <br />
                  <Button variant="contained" onClick={(e) => {handleNavigateToLogin(e)}} style={buttStyle}>Return to Login</Button>
                  <br />
                  <br />
                </div>
            }
          </div>
        </div>
    )
}

 // TODO add hover text and disable upload button when no files are selected
 //      

export default Faq;
