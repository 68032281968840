//import fish from './../dad_fish.jpg'
import React from 'react'; //ChangeEvent, 
import { useNavigate } from "react-router-dom";
import { Button } from '@mui/material';

import '../style/Faq.css'

const buttStyle = { backgroundColor: '#1b5e20' };

const Faq = ({loginNav}) => {
  const navigate = useNavigate();

  const handleNavigateToLogin = async e => {
    e.preventDefault();
    navigate("../", { replace: true }); 
  }

    return (
        <div className = 'faq'>
          <div className='faq-align'>
            <h4 className='faq-question'>What does this site do?</h4>
            <p>The Digital Leech Finder takes your uploaded Instagram data and shows you who isn't following you back, who you aren't following back, along with other fun analytics!</p>
            <p>You may run as many reports for this data as you would like so long as you have an appropriate subscription for your account. Subscription happens via a secure stripe payment.</p>
            <h4 className='faq-question'>Important Definitions</h4>
            <h4>Digital Leeches:</h4>
                <ul><ul><li>Someone who unfollows you after you follow them back. Sneaky devils.</li></ul></ul>
            <h4>Suckers:</h4>
                <ul><ul><li>People who follow you that do not follow you back.</li></ul></ul>
            <h4>Subscriptions:</h4>
                <ul><ul>
                  <li>In order to find your leeches in a <b>Report</b> (see next definition), you must be subscribed to a subscription tier.</li><p></p>
                  <li>Your tier determines how big of an account you will be allowed to run reports for.</li><p></p>
                  <li>These are the subscription tiers:</li><p></p>
                  <ul><ul>
                    <li><b>Leech Slayer</b>, which will let your run reports for accounts with up to 10,000 followers.</li><p></p>
                    <li><b>Leech Surgeon</b>, which will let your run reports for accounts with up to 100,000 followers.</li><p></p>
                    <li><b>Leech Slayer</b>, which will let your run reports for accounts with no follower limit.</li><p></p>
                  </ul></ul>
                </ul></ul>
            <h4>Report:</h4>
                <ul><ul>
                    <li>The result of the processing of your instagram data. Each report contains your Leeches, your Suckers, along with your Follower and Following list</li><p></p>
                    <li>The title of your Report will be the date and time that you processed your data.</li><p></p>
                    <li>You can select which report you want to view using the drop down menu at the top of the "Dashboard" page.</li><p></p>
                    <li>You can export whichever Report you are looking at to a CSV using the "Download as CSV" button at the bottom of the "Dashboard" page</li><p></p>
                </ul></ul>

            <br />

            <h4 className='faq-question'>I have my Leeches, now what?</h4>
            <p>Time to cut them out! I would suggest at most removing 5-10 Digital Leeches an hour so you don't get suspended or banned from instagram for "suspicious" or "spam" behavior.</p>
            <p>I tend to unfollow a few Digital Leeches each time I boot up the app. I'm always happy when I do, I feel like it cleanses the soul.</p>
            <br />

            <h4 className='faq-question'>Isn't this a bit complicated?</h4>
            <p>Yeah, it is fairly complex.</p>
            <p>This is more work than other methods, but I haven't found anything else that is respectful of your privacy and does not hit a social media site's API.</p>
            <p>If you're using an app that uses your account to automate these things (scaping data from your account's page, automatic unfollowing, etc.), they usually don't work.</p>
            <p>On top of that, you're risking:</p>
            <ul>
              <li>Having your account used for nefarious purposes by bad actors (bad people using your account for bad things).</li>
              <ul>
                <li>Examples:</li><p></p>
                <ul>
                  <li>Social engineering</li><p></p>
                  <li>Profiling you using your post data</li><p></p>
                  <li>AI recreations of your likeness for Bitcoin scams</li><p></p>
                </ul>
              </ul>
              <li>If the app automatically pings the social media site too often with "bad behavior", you're putting your account at risk for being suspended or banned.</li>
            </ul>

            <h4 className='faq-question'>I think this site should have a new feature or I found a bug.</h4>
            <p>Hit me up and I'll see what I can do: <a href="mailto:support@digitalleechfinder.com">support@&#8203;digitalleechfinder&#8203;.com</a></p>
            <p>If your accounts aren't processing, the easiest thing you can do is compress your follower and following files into a single .zip file and email them over to me. Put something like "YOUR SITE ISN'T PROCESSING MY DATA" and you'll get my attention.</p>

            { loginNav &&
                <div>
                  <Button variant="contained" onClick={(e) => {handleNavigateToLogin(e)}} style={buttStyle}>Return to Login</Button>
                  <br />
                  <br />
                </div>
            }
          </div>
        </div>
    )
}

 // TODO add hover text and disable upload button when no files are selected
 //      

export default Faq;
