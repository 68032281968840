import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import { Button } from '@mui/material';

import { get } from '../config/axiosConfig'

import './../style/Login.css';

const buttStyle = { backgroundColor: '#1b5e20' };

const Register = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();

    const handleNavigateToLogin = async e => {    
      e.preventDefault();
      navigate("../", { replace: true }); 
    }

    async function registerUser(email, password) {
      if (password !== confirmPassword) {
        alert("Passwords don't match");
        return;
      }

      const registerParams = `register?email=${email}&password=${password}`
      try {
        let response = await get(registerParams);
        console.log(response);
        alert("Registration Successful! Check your email for an activation URL.\nCheck your spam filter if you can't find it.");
      } catch (e) {
        alert('Error registering account. Is this email already an account?');
        console.log(e);
      }
    }

    const handleSubmit = async e => {
        e.preventDefault();
        await registerUser(email, password, confirmPassword);
      }

    return (
        <div className="login-wrapper">
          <h1>Register Here</h1>
          <h3>You will recieve an email, click the link to activate your account</h3>
          { /*<h3>By creating an account, you are agreeing to our <a href="https://www.google.com/search?q=instagram+download+data" target="_blank" rel="noopener noreferrer">Privacy Policy</a></h3> */}
          <form onSubmit={handleSubmit}>
            <label>
              <p>Email</p>
              <input type="text" onChange={e => setEmail(e.target.value)}/>
            </label>
            <label>
              <p>Password</p>
              <input type="password" onChange={e => setPassword(e.target.value)}/>
            </label>
            <label>
              <p>Password Again</p>
              <input type="password" onChange={e => setConfirmPassword(e.target.value)}/>
            </label>
            <div>
              <button type="submit">Submit</button>
            </div>
          </form>
          <div className='link-wrapper'>
            <Button variant="contained" onClick={(e) => {handleNavigateToLogin(e)}} style={buttStyle}>or Return to Login</Button>
          </div>
        </div>
      )
}

export default Register;