//import fish from './../dad_fish.jpg'
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress } from '@mui/material'; //, TextField, Input Typography

import { post } from './../config/axiosConfig'
import { DESKTOP_TUTORIAL_URL, MOBILE_TUTORIAL_URL } from './../config/envVars'

import './../style/Upload.css';

import JSZip from 'jszip';

const Upload = ({setCurrentPath}) => {
  const navigate = useNavigate();
  const [fileList, setFileList] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search) // subMessage
    if(params.get('subSuccess') === 'true'){
      alert('You have subscribed successfully!');
    }
  }, [])


  const handleFileChange = (e) => {
    setFileList(e.target.files);
  };

  async function unzipCorrectJsonFiles (){
    console.log('running unzipCorrectJsonFiles');
    let tempData = new FormData();
    const zip = await JSZip.loadAsync(files[0]);
    // create a promise per going-to-be-unzipped file

    let i = 0;
    for (const [path, file] of Object.entries(zip.files)) {
      const currentFile = file.name.split('/').pop(); // get that path outta here
      if ( currentFile.includes('follow') && currentFile.endsWith('.json') && !currentFile.includes('hashtag') && !currentFile.includes('accounts') ) {
        let content = await zip.file(file.name).async("string");
        tempData.append(`file` + i, new Blob([content], { type: 'application/json' }), currentFile);
        i++;
      }
    }

    console.log('finish unzipCorrectJsonFiles');
    return tempData;
  }

  async function handleUploadClick() {
      if (!fileList) {
        return;
      }

      // must send files for server processing, frontend rejects file processing functions
      /**
       * order of operations:
       * 
       * let data get processed
       * run math, but have popup modal after
       * if you have enough followers, set the report to active
       * 
       * if not, don't but tease the tip
       */

      try {
        let follower_following_data = new FormData();
        
        let isZip = files[0].type === 'application/zip' || files[0].type === 'application/x-zip-compressed'; // if it's a zip, do da zip logic. else JSON logic
        let isJSON = files[0].type === 'application/json';
  
        setIsLoading(true);
  
        if(isZip){
          follower_following_data = await unzipCorrectJsonFiles();
        }
  
        if(isJSON){
          files.forEach((file, i) => {
            follower_following_data.append(`file` + i, file, file.name);
          });
        }

        console.log('sending post call');
        let response = await post('report/submit', follower_following_data);
        if(response == null) {
          alert("Submission Failed, Insufficient Subscription for Creating Report");
          setIsLoading(false);
          return;
        }

        let alertMessage = 'Data Submitted Successfully'
        if(response && response.data){
          const data = response.data;
          if(data.leech_count) alertMessage += `, ${response.data.leech_count} Digital Leeches found!`
          if(data.promo_credit) alertMessage += `\n\nYou have used your free report promo credit`;
        }

        setIsLoading(false); // not essential, but shuts off the loading animation before nav to dashboard
        alert(alertMessage);
        setCurrentPath("/dashboard");
        navigate("/dashboard", { replace: true }); //automatically go to dashboard after upload (after ok click on alert)
      } catch (e){
        setIsLoading(false);
        console.log(e);
      }
    }

      // 👇 files is not an array, but it's iterable, spread to get an array of files
      const files = fileList ? [...fileList] : [];
      const buttStyle = { backgroundColor: '#1b5e20' };

    return (
        <div className = 'Upload'>
            <p>This site takes data you download from Instagram and tells you who isn't following you back</p>
            <br />

            <iframe class='ifwame' title='tut' width="600px" height="340px" src={DESKTOP_TUTORIAL_URL}></iframe>
            <iframe class='ifwame_mobile' title='tut_mobile' width="340px" height="600px" src={MOBILE_TUTORIAL_URL}></iframe>

            <br /><br />

            <p>
                Upload your follower and following JSON files
            </p>
            <div className='file-div'>
              {/*<p>Drag files here</p><p>or</p>*/}
              <input type="file" onChange={handleFileChange} multiple />

              <ul>
                {files.map((file, i) => ( // - {file.type}
                  <li key={i} className='file-list'>
                    {file.name}
                  </li>
                ))}
              </ul>

              <div className='upload-button'>
                {isLoading ?
                <div className='upload-waiting'>
                  <CircularProgress />
                  <p>Uploading, Please Wait</p>
                </div>
                :
                <Button variant="contained" onClick={(e) => {handleUploadClick(e)}} style={buttStyle}>
                  Upload
                </Button> /**<Typography fontFamily={['-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'].join('')}>Upload</Typography> */
                }
              </div>
            </div>
        </div>
    )
}

 /** 
  * TODO add hover text, highlight area and explain you can drag/drop files,
  * and disable upload button when no files are selected
  */ 

export default Upload;
