import React, { useState, useEffect }  from 'react'
import { CSVLink } from "react-csv";
import Select from 'react-select'
import { BsGraphUp, BsInfoSquare } from 'react-icons/bs'; // BsInfoSquare

import LineChartDialog from './LineChartDialog';
import { get } from './../config/axiosConfig';

import './../style/Dashboard.css';

// const buttStyle = { backgroundColor: '#1b5e20' };

const Dashboard = ({token}) => {
    const [reportList, setReportList] = useState([]); // is this alright or should this default to an array of objects
    const [leeches, setLeeches] = useState([]);
    const [suckers, setSuckers] = useState([]);
    const [followingList, setFollowingList] = useState([]);
    const [followerList, setFollowerList] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [showGraphDialog, setShowGraphDialog] = useState(false);
    const [graphData, setGraphData] = useState([]);
    const colSize = 30;
    const rowSize = 15;

    const infoIconSize = 20;

    function displayReportData(data){
      const followers = [];
      const following = [];
      const leeches = [];
      const suckers = [];

      for(let usernameRow of data){
        const username = usernameRow.username;
        // if follower bool true push to followers. If following bool is false also push to suckers
        // if following bool is true push to following. If follower bool is false also push to leeches
        if(usernameRow.is_follower) {
          followers.push(username)
          if(!usernameRow.is_following) suckers.push(username);
        }
        if(usernameRow.is_following) {
          following.push(username)
          if(!usernameRow.is_follower) leeches.push(username);
        }
      }

      setFollowerList(followers);
      setFollowingList(following);
      setLeeches(leeches);
      setSuckers(suckers);
    }

    useEffect( () => {
        async function fetchData(){
            let reportListResponse = await get('report/list'); // get list of reports
            // const reportListSorted = reportListResponse.data.sort(); // order cronologically
            if( !reportListResponse || !reportListResponse.data ) {
              return;
            }

            let reportMetaArray = [];
            let graphDataArrayReversed = [];
            for(let element of reportListResponse.data){
              reportMetaArray.push({
                value: element.id,
                label: new Date(element.create_ts).toLocaleString()
              });

              graphDataArrayReversed.push({
                createdTs: new Date(element.create_ts).toLocaleDateString('en-US'),
                numLeeches: element.num_leeches,
                numSuckers: element.num_suckers,
                numFollowers: element.num_followers,
                numFollowing: element.num_following,
              });
            }

            setReportList(reportMetaArray);
            setGraphData(graphDataArrayReversed.reverse());

            const responseReportData = await get(`report/${reportListResponse.data[0].id}`); // get latest report from response
            console.log(responseReportData);
            displayReportData(responseReportData.data)
        }

        fetchData();
     }, []);

    async function getAndSetDashboardReportById(e) {
      console.log(e.value);
      const responseReportData = await get(`report/${e.value}`, token); // get latest report from response
      displayReportData(responseReportData.data)
    }

    async function generateCsvData() {
      setCsvData([
        ['Leeches', ...(leeches)],
        ['Suckers', ...(suckers)],
        ['Followers', ...(followerList)],
        ['Following', ...(followingList)]
      ]);
    }

    function describeAlert(string){
      alert(string);
    }

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted black',
        color: state.isSelected || state.isFocused ? 'white' : 'black',
        padding: '20px',
        backgroundColor: state.isSelected || state.isFocused ? '#1b5e20' : 'white',
      })
    }

  // TODO replace Select with AsyncSelect to load initial value?

    return (
        <>
        { /**
         * populate 2 separate graphs, slight opacity to follower/following and leech/sucker text boxes
         * One shows all followers vs following over time (1 data point per report), other leeches vs suckers
         * 
         * Export report to excel doc
         * 1st sheet, metadata sheet (followers, following, leech and sucker count)
         * 2nd sheet, 4 columns with emails for followers, following, suckers and leeches
         */
         showGraphDialog && <LineChartDialog showGraphDialog={showGraphDialog} setShowGraphDialog={setShowGraphDialog} graphData={graphData}/>
        }
          <div className='flex-row'>
              <div className="flex-row">
                {/* <h3 className='mobileHidden'>Report</h3> */}
                <Select
                  defaultValue={'Current Report'}
                  className='select-dropdown'
                  onChange={getAndSetDashboardReportById}
                  options={reportList}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                    ...theme.colors,
                      text: 'black',
                      primary25: '#3599B8',
                      primary: '#3599B8',
                      neutral80: 'black',
                      color: 'black'
                    },
                  })}
                  styles={customStyles}
                />
                <button type="button" className='mobileHidden' onClick={() => setShowGraphDialog(true)}><BsGraphUp size='40'></BsGraphUp></button>
                <div className='linkToButton'>
                    <CSVLink
                      data={csvData}
                      asyncOnClick={true}
                      onClick={(e) => {generateCsvData(e)}}
                      /* className='csv-link' */
                    >
                    {'Download CSV'}
                    </CSVLink>
                </div>
              </div>
          </div>
          <div className="flex-col">
            <div className="flex-row">
              <div className="leeches">
                <h4>Leeches: {leeches.length} <button type="button" className='mobileHidden' onClick={() => describeAlert("Accounts you follow that aren't following you ")}><BsInfoSquare size={infoIconSize}></BsInfoSquare></button></h4>
                <textarea
                  cols={colSize}
                  rows={rowSize}
                  value={leeches?.join("\r\n")}
                  onChange={(e) => setLeeches(e.target.value?.split("\r\n"))}
                ></textarea>
              </div>
              <div className="suckers">
                  <h4>Suckers : {suckers.length} <button type="button" className='mobileHidden' onClick={() => describeAlert("Accounts following you that you don't follow")}><BsInfoSquare size={infoIconSize}></BsInfoSquare></button></h4>
                  <textarea
                    cols={colSize}
                    rows={rowSize}
                    value={suckers?.join("\r\n")}
                    onChange={(e) => setSuckers(e.target.value?.split("\r\n"))}
                  ></textarea>
              </div>
            </div>

            <div className="flex-row">
              <div className="following">
                <h4>Following: {followingList.length}</h4>
                <div>
                  <textarea
                    cols={colSize}
                    rows={rowSize}
                    value={followingList?.join("\r\n")}
                    onChange={(e) => setFollowingList(e.target.value?.split("\r\n"))}
                  ></textarea>
                </div>
              </div>
            <div className="followers">
                <h4>Followers: {followerList.length}</h4>
                <div>
                  <textarea
                    cols={colSize} 
                    rows={rowSize}
                    value={followerList?.join("\r\n")}
                    onChange={(e) => setFollowerList(e.target.value?.split("\r\n"))}
                  ></textarea>
                </div>
              </div>
              </div>
          </div>
          <p></p>
        </>
    )
}

export default Dashboard;
