import React, { useContext } from 'react'; // , { useState, useEffect }
import { useNavigate } from "react-router-dom";
import { Button } from '@mui/material';

import { post } from '../../config/axiosConfig';

import { ProductContext } from "../../state/contexts";

import './../../style/Products.css';

const SubscriptionComponent = ({ prod, prodImg }) => {
  const navigate = useNavigate();
  const buttStyle = { backgroundColor: '#1b5e20' };
  const { setClientSecret } = useContext(ProductContext); // setSelectedProduct, setSelectedQuantity, 

  async function beginPurchase(event) {
    // here, we call stripe to create session, get session then pass to checkout
    event.preventDefault();
    let price_id = window.env.ENVIRONMENT === 'PROD' ? prod.price_id : prod.price_id_dev;
    let response = await post('create-subscription-session/' + price_id);
    console.log('response:');
    console.log(response.data)
    setClientSecret(response.data.clientSecret);
    navigate("../checkout", { replace: true });
  }

  return (
    <div className="product" >
      <img src={prodImg} alt={prod.name} className = "product-img" />
      <section>
        <h2>{prod.name || ''}</h2>
        <p>{prod.desc}</p>
        <div className='inputAlign'>
        <h3>{'$' + (prod.price / 100) || ''} per month</h3>
          <div></div>
          <Button className='purchaseButton' variant="contained" onClick={(e) => {beginPurchase(e)}} style={buttStyle}>
            PURCHASE
          </Button>
        </div>
      </section>
    </div>
  )
}

export default SubscriptionComponent
