const sites = [
    'http://localhost:3000/',
    'https://digitalleechfinder.com/',
    'https://digitalleechfinder.onrender.com/'
]; 

function createLoginSitePaths () {
    const builtPaths = [];
    for(const site of sites) {
        builtPaths.push(site);
        builtPaths.push(site + 'register/');
        builtPaths.push(site + 'faq-login/');
    }
    return builtPaths;
}

export const loginSitePaths = createLoginSitePaths();
