import { get } from '../config/axiosConfig'

export async function getSubscriptionTier (){
    try {
      function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
      }    
      await timeout(1000); //for 1 sec delay
    
      let response = await get('user/subscription');
      // console.log(response.data)
      return(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  /**
 * 
 * @returns list of all current active subscriptions for user
 */
export async function getSubscriptionTierAll (){
  try {
    let response = await get('user/subscription/all');

    return(response.data);
  } catch (error) {
    console.log(error);
  }
}