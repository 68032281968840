const cname = 'dilf_cookie';

export default function getDilfCookie() {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie) || null;
    if(decodedCookie == null){
        return null;
    }
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            console.log(c.substring(name.length, c.length));
            return c.substring(name.length, c.length);
        }
    }
    return null;
}

export function setDilfCookie(cvalue, exdays=3) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function eraseDilfCookie() {
    console.log('eraseDilfCookie')
    let cvalue = getDilfCookie(cname)

    const d = new Date();
    d.setTime(d.getTime() - (3*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    console.log(document.cookie);
}