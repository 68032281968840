import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import { Button } from '@mui/material';

import { setDilfCookie } from './../Cookies';
import { axiosClient } from './../config/axiosConfig'
import { DESKTOP_TUTORIAL_URL, MOBILE_TUTORIAL_URL } from './../config/envVars'

import leech_logo from './../img/leech_logo.png';

import './../style/Login.css';

const buttStyle = { backgroundColor: '#1b5e20' };

const Login = ({setToken}) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    
    async function loginUser(email, password) {
      const loginParams = `login?email=${email}&password=${password}`
    
      try {
        let response = await axiosClient.get(loginParams);
        return response.data;
      } catch (e) {
        console.log(e);
      }
    }

    const handleNavigateToRegister = async e => {
      e.preventDefault();
      navigate("../register", { replace: true }); 
    }

    const handleNavigateToFaq = async e => {
      e.preventDefault();
      navigate("../faq-login", { replace: true }); 
    }

    const handleNavigateToContact = async e => {
      e.preventDefault();
      navigate("../contact-login", { replace: true }); 
    }
    
    const handleOpenTutorial = async e => {
      e.preventDefault();
      window.open(DESKTOP_TUTORIAL_URL, '_blank').focus();
    }
    const handleOpenMobileTutorial = async e => {
      e.preventDefault();
      window.open(MOBILE_TUTORIAL_URL, '_blank').focus();
    }

    const handleSubmit = async e => {
      e.preventDefault();
      const token = await loginUser(email, password);
      if(token == null){
        alert('Unable to login. Are the email and password correct?');
      }

      // Redundant, but so we can load the token from the cookie on reload
      setDilfCookie(token);
      setToken(token);
    }

    return (
        <div className="login-wrapper">
          <div className='login-title-and-logo'>
            <div className = "login-img"><img src={leech_logo} alt='leech-logo'/></div>
            <h1>Digital<br />Leech<br />Finder</h1>
          </div>
          <p>A place to see who isn't following you back on instagram.</p>
          <div className='login-block'>
              <div className='login-register-wrapper'>
                <h3>Log In</h3>
                <h3>or</h3>
                <div className="link-wrapper">
                  <Button variant="contained" onClick={(e) => {handleNavigateToRegister(e)}} style={buttStyle}>Register</Button>
                </div>
              </div>
              <form onSubmit={handleSubmit} className='login-form'>
                <label>
                  <p>Email</p>
                  <input type="text" onChange={e => setEmail(e.target.value)}/>
                </label>
                <label>
                  <p>Password</p>
                  <input type="password" onChange={e => setPassword(e.target.value)}/>
                </label>
                <div>
                  <button type="submit">Submit</button>
                </div>
              </form>
              </div>
              <p class='desktop_tutorial'>Watch the <Button variant="contained" onClick={(e) => {handleOpenTutorial(e)}} style={buttStyle}>Tutorial</Button></p>
              <p class='mobile_tutorial'>Watch the <Button variant="contained" onClick={(e) => {handleOpenMobileTutorial(e)}} style={buttStyle}>Tutorial</Button></p>
          <p>Or check out our <Button variant="contained" onClick={(e)=>{handleNavigateToFaq(e)}} style={buttStyle}>FAQ</Button></p>
          <Button variant="contained" onClick={(e)=>{handleNavigateToContact(e)}} style={buttStyle}>Contact</Button>
        </div>
      )
}

export default Login;