import React, { useEffect, useState } from 'react'; //ChangeEvent, 
import { useNavigate } from "react-router-dom";
import Select from 'react-select'

import { getSubscriptionTier } from '../util/subscription'

// import { get } from '../config/axiosConfig'
import { eraseDilfCookie } from './../Cookies'

import '../style/Header.css';

import { Button } from '@mui/material'; //TextField

import leech_logo from './../img/leech_logo.png';

const Header = ({currentPath, setCurrentPath}) => {
  const navigate = useNavigate();
  const noSubscriptionMessage = 'No Subscription Found';
  // if promo credit, write "First report free." Otherwise display tokens
  // const [tokenWallet, setTokenWallet] = useState({token_wallet: 0, promo_credit: 0});
  const [subscriptionTier, setSubscriptionTier] = useState({
    id: null,
    name: noSubscriptionMessage,
    user_limit: 0,
    promo_credit: 0
  });

  const pageSelectOptions = [
    { value: '', label: 'Upload'},
    { value: 'dashboard', label: 'Dashboard'},
    { value: 'subscriptions', label: 'Purchase Subscription'},
    { value: 'account', label: 'Account'},
    { value: 'faq', label: 'FAQ'},
    { value: 'contact', label: 'Contact'},
    { value: 'logout', label: 'Logout'}
  ]

  const buttStyle = { backgroundColor: '#1b5e20' }; // red: e54b4b , orange = ef6c00

  useEffect(() => { // initial token wallet load on login
    (async() => {
      const subTierCurrent = await getSubscriptionTier();
      if(subTierCurrent){
        setSubscriptionTier(subTierCurrent);
      }
    })();
  }, [currentPath])

  async function handleButtonNav(e){
    e.preventDefault();
    console.log(subscriptionTier);
    let path;
    if(!subscriptionTier?.id){
      path = { value: 'subscriptions' };
    } else {
      path = { value: 'account' };
    }
    await handleSelectNavigate(path);
  }

  async function handleSelectNavigate(selectedPage) {
    const path = selectedPage.value;

    if(path === 'logout'){
      navigate("../", { replace: true });
      eraseDilfCookie('dilf_cookie');
      function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
      }
      await timeout(250); //for .25 sec delay
      window.location.reload(false);
      return;
    }

    navigate("/" + path, { replace: true });
    setCurrentPath("/" + path);
  }
  
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      height: '100%',
      width: '100%',
      borderBottom: '1px dotted black',
      color: state.isSelected || state.isFocused ? 'white' : 'black',
      backgroundColor: state.isSelected || state.isFocused ? '#1b5e20' : 'white',
    })
  }

  // Try Token Will now be button to go to products

  /* Header is HALF until hit media query, then full */
  return (
      <div className = 'Header'>
        <div className = 'HeaderTitle'>
          <img src={leech_logo} alt='leech-logo'/> 
          <h3>Digital Leech Finder</h3>
        </div>
        <div className = 'HeaderNav'>
          <Button
            variant="contained"
            onClick={(e) => {handleButtonNav(e)}}
            style={buttStyle}>
              {subscriptionTier?.promo_credit > 0 ? 'First Report Free!' : 'Subscription: ' + subscriptionTier?.name || noSubscriptionMessage}
          </Button>
          <Select
            defaultValue={{ value: '', label: 'Upload' }}
            onChange={handleSelectNavigate}
            options={pageSelectOptions}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
              ...theme.colors,
                text: 'black',
                primary25: '#3599B8',
                primary: '#3599B8',
                neutral80: 'black',
                color: 'black'
              },
            })}
            styles={customStyles}
          />
        </div>
      </div>
  )
}

export default Header;
