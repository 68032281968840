export const productsLocal = [
  {
    name: `Leech Picker`,
    desc: 'Accounts Up to 10,000 Followers',
    price: 100,
    img:
      'img/leech_picker',
    id: 1,
    price_id_dev: 'price_1OGyfJEWZ7C8QEhsMIC7eraf',
    price_id: 'price_1OFTiAEWZ7C8QEhsrvPFD4ht'
  },
  {
    name: `Leech Surgeon`,
    desc: 'Accounts Up to 100,000 Followers',
    price: 1000,
    img:
      'img/leech_surgeon',
    id: 2,
    price_id_dev: 'price_1OGyfiEWZ7C8QEhs3nv8uiME',
    price_id: 'price_1OFTl9EWZ7C8QEhswxAYqws9'
  },
  {
    name: `Leech Slayer`,
    desc: 'No Account Follower Limit',
    price: 10000,
    img:
      'img/leech_slayer',
    id: 3,
    price_id_dev: 'price_1OGygCEWZ7C8QEhss6pTY1DB',
    price_id: 'price_1OFTmjEWZ7C8QEhs4xjj4zWC'
  }
]

/* replace with low res for local, scale up for load */

/* price_1OOnBqEWZ7C8QEhsDQZVvEQ3 */