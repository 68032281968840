let api_url_temp = 'http://localhost:3456/'
let base_url_temp = 'http://localhost:3000/';
let stripe_api_key_temp = '';

if(window.env?.ENVIRONMENT === 'PROD'){
    api_url_temp = window.env?.API_URL;
    base_url_temp = window.env?.BASE_URL;
    stripe_api_key_temp = window.env?.STRIPE_API_KEY;
} else if(window.env?.ENVIRONMENT === 'DEV'){
    api_url_temp = window.env?.API_URL_DEV;
    base_url_temp = window.env?.BASE_URL_DEV;
    stripe_api_key_temp = window.env?.STRIPE_API_KEY_DEV;
}

export const API_URL=api_url_temp;
export const BASE_URL=base_url_temp;
export const STRIPE_API_KEY=stripe_api_key_temp;
export const DESKTOP_TUTORIAL_URL='https://www.youtube.com/embed/' + window.env?.DESKTOP_TUTORIAL_URL_ID;
export const MOBILE_TUTORIAL_URL='https://www.youtube.com/embed/' + window.env?.MOBILE_TUTORIAL_URL_ID;