/* Packages, major paths, authentication, style */
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState } from "react"; //useEffect, 

import Header from './components/Header';
import Account from './components/Account';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import Upload from './components/Upload';
import Subscriptions from './components/Product/Subscriptions';
import SubscriptionCheckoutWrapper from "./components/SubscriptionCheckoutWrapper";
// import CheckoutWrapper from "./components/CheckoutWrapper";
import Faq from './components/Faq';
import Contact from './components/Contact';
// import Footer from './components/Footer';
// import Activate from './components/Activate'; /* <Route path="activate/*" element={ <Activate /> } /> */
// import ContactModalLauncher from './components/ContactModalLauncher'

import { ProductContext } from "./state/contexts";

import getDilfCookie from './Cookies'

import { loginSitePaths } from './const/loginSitePaths'

import './style/App.css';

/*
  Leech Colors
  #212121 - Black
  #ffc107 - Yellow
  #827717, #1b5e20 - Green
  #ef6c00 - Orange
*/

function App() {
  const cookieName = 'dilf_cookie';
  const [ token, setToken ] = useState(getDilfCookie(cookieName || ''));
  const [ selectedProduct, setSelectedProduct ] = useState({});
  const [ selectedQuantity, setSelectedQuantity ] = useState(null);
  const [ clientSecret, setClientSecret ] = useState(null);
  const [ currentPath, setCurrentPath ] = useState('/')

  const checkoutObj = {
    selectedProduct,
    setSelectedProduct,
    selectedQuantity,
    setSelectedQuantity,
    clientSecret,
    setClientSecret
  };

  if(!token) {
    if (!loginSitePaths.includes(window.location.href) && !window.location.href.includes('activate')) {
      let path = window.location.href; // resets url to login page if no token + trying to access app
      let base_url_end = path.lastIndexOf('/');
      window.location.href = path.substring(0,base_url_end);
    } 
    return  (
      <>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login setToken={setToken}/>} />
                <Route path="register/*" element={<Register />} />
                <Route path="faq-login/*" element={ <Faq loginNav={true}/> } />
                <Route path="contact-login/*" element={ <Contact loginNav={true}/> } />
            </Routes>
        </BrowserRouter>
        { /*<Footer />*/ }
      </>
    );
  }

  return (
    <div className="App">
        <BrowserRouter>
        <Header currentPath={currentPath} setCurrentPath={setCurrentPath} />
          <div className="App-body">
          <ProductContext.Provider value={checkoutObj}>
            <Routes>
              <Route path="/" element={ <Upload setCurrentPath={setCurrentPath}/> } />
              <Route path="dashboard/*" element={ <Dashboard /> } />
              <Route path="subscriptions/*" element={ <Subscriptions /> } />
              <Route path="checkout/*" element={ <SubscriptionCheckoutWrapper /> }/>
              <Route path="account/*" element={ <Account /> } /> {/**This will be for subscription handling, later password reset */}
              <Route path="faq/*" element={ <Faq loginNav={false}/> } />
              <Route path="contact/*" element={ <Contact loginNav={false}/> } />
            </Routes>
          </ProductContext.Provider>
          </div>
        </BrowserRouter>
    </div>

  );
}

export default App;
