import React, {useState, useEffect} from 'react' //, {useState}

import SubscriptionComponent from './SubscriptionComponent'
import { productsLocal } from '../../const/productsLocal'
import { get } from '../../config/axiosConfig';

import picker from '../../img/leech_picker.png';
import surgeon from '../../img/leech_surgeon.png';
import slayer from '../../img/leech_slayer.png';

import './../../style/Products.css'

const Subscriptions = () => {
  const [ products, setProducts ] = useState(productsLocal);
  const [ subscriptionReq, setSubscriptionReq] = useState({ name: '', user_limit: 0, account_size: 0});
  const prodImgs = [picker, surgeon, slayer];

  useEffect(() => {
    /*
    const setUserProducts = async () => {
      try {
        let response = await get('product');
        setProducts(response.data);
        console.log("Got products: " + response.data);
      } catch (error) {
        console.log(error);
      }
    }
    */
    const getSubscriptionReq = async () => {
      try {
        let response = await get('user/subscription/req');
        setSubscriptionReq(response.data);
      } catch (error) {
        console.log(error);
      }
    }

    getSubscriptionReq();
    // setUserProducts(); TODO just worry about stripe for now, go back and add info to DB with a dev and prod flag for selecting price id
  }, []);

  // have a state variable per product, be able to send that to checkout along with product id
  return (
    <div className='product-page'>
      <br />
      <div className='product-container'>
        { products.map(prod => {
            return <SubscriptionComponent key={prod.id} prod={prod} prodImg={prodImgs[prod.id -1]} />
        })}
      </div>
      <p>{subscriptionReq.name ? `Based on your latest report with ${subscriptionReq?.account_size} followers, we recommend the "${subscriptionReq?.name}" tier` : ''}</p>
      <p></p>
    </div>
  )
}

export default Subscriptions
